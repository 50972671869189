import constate from 'constate'
import qs from 'query-string'
import { useState } from 'react'

import useSyncSearchParam from '../../../../router/useSyncSearchParam'

const QUERY_KEY = 'q'

function useSearchPhrases(): [string[], (tags: string[]) => void] {
  const state = useState(() => {
    // read query param initially and set state
    const parsedQuery = qs.parse(window.location.search)
    const value = parsedQuery[QUERY_KEY]
    if (value == null) {
      return []
    }
    if (typeof value === 'string') {
      return [value]
    }
    return value.filter(Boolean) as string[]
  })
  useSyncSearchParam(QUERY_KEY, state[0])
  return state
}

export const [SearchPhrasesProvider, useSearchPhrasesContext] =
  constate(useSearchPhrases)
