import analytics from '@capturi/analytics'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'

type Props = {
  setNumberOfConversations: (conversations: number) => void
  numberOfConversations: number
  setEnableRandomizer: (flag: boolean) => void
} & BaseModalComponentProps

const StartRandomizerModal: React.FC<Props> = ({
  setNumberOfConversations,
  numberOfConversations,
  onClose,
  setEnableRandomizer,
}) => {
  const [number, setNumber] = useState<number>(numberOfConversations)
  const handleSubmit = (): void => {
    analytics.event('conversationPage-enableRandomizer')
    setNumberOfConversations(number)
    setEnableRandomizer(true)
    onClose()
  }
  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Trans>Quality assurance</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="bold">
              <Trans>
                Select number of conversations for quality assurance
              </Trans>
            </Text>
            <Flex>
              <NumberInput
                my="2"
                title="number of conversations"
                min={1}
                max={100}
                mx="4"
                onChange={(e) => {
                  setNumber(Number(e))
                }}
                value={number}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
            <Text color="GrayText">
              <Trans>
                Conversations will be randomly selected. And already reviewed
                conversations will not be included.
              </Trans>
            </Text>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="end" gap={3}>
              <Button onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button primary onClick={handleSubmit}>
                <Trans>Start QA</Trans>
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default StartRandomizerModal
